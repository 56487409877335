<template>
  <div
    v-if="editor"
    class="editor"
  >
    <div class="control-group">
      <div class="button-group">
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          :class="{ 'active': editor.isActive('heading', { level: 5 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
          H5
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          :class="{ 'active': editor.isActive('bold') }"
          @click.prevent="editor.chain().focus().toggleBold().run()"
        >
          <feather-icon icon="BoldIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          :class="{ 'active': editor.isActive('italic') }"
          @click.prevent="editor.chain().focus().toggleItalic().run()"
        >
          <feather-icon icon="ItalicIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          :class="{ 'active': editor.isActive('bulletList') }"
          @click.prevent="editor.chain().focus().toggleBulletList().run()"
        >
          <feather-icon icon="ListIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          @click.prevent="editor.chain().focus().setHorizontalRule().run()"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          @click.prevent="editor.chain().focus().setHardBreak().run()"
        >
          <feather-icon icon="CornerDownLeftIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          @click.prevent="editor.chain().focus().undo().run()"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
        <b-button
          class="btn-icon mx-25"
          variant="flat-secondary"
          @click.prevent="editor.chain().focus().redo().run()"
        >
          <feather-icon icon="RotateCwIcon" />
        </b-button>
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive('strike') }"-->
        <!--          @click.prevent="editor.chain().focus().toggleStrike().run()"-->
        <!--        >-->
        <!--          Strike-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive('highlight') }"-->
        <!--          @click.prevent="editor.chain().focus().toggleHighlight().run()"-->
        <!--        >-->
        <!--          Highlight-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"-->
        <!--          @click.prevent="editor.chain().focus().setTextAlign('left').run()"-->
        <!--        >-->
        <!--          Left-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"-->
        <!--          @click.prevent="editor.chain().focus().setTextAlign('center').run()"-->
        <!--        >-->
        <!--          Center-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"-->
        <!--          @click.prevent="editor.chain().focus().setTextAlign('right').run()"-->
        <!--        >-->
        <!--          Right-->
        <!--        </b-button>-->
        <!--        <b-button-->
        <!--          size="sm"-->
        <!--          variant="flat-secondary"-->
        <!--          :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"-->
        <!--          @click.prevent="editor.chain().focus().setTextAlign('justify').run()"-->
        <!--        >-->
        <!--          Justify-->
        <!--        </b-button>-->
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import DOMPurify from 'dompurify'

export default {
  components: {
    BButton,
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      onUpdate: () => {
        // HTML
        // Allow only a super restricted set of tags and attributes
        const clean = DOMPurify.sanitize(this.editor.getHTML(), {
          ALLOWED_TAGS: ['b', 'p', 'strong', 'h5', 'em', 'ul', 'li', 'hr', 'br'],
        })
        this.$emit('input', clean)

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
<style lang="scss">

</style>
