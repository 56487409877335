<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.6"
    spinner-medium
    spinner-variant="primary"
    spinner-type="grow"
  >
    <div
      v-if="!restricted"
      v-show="!loading"
      :key="elementKey"
    >
      <validation-observer ref="simpleRules">
        <b-form
          ref="offer"
          @submit.prevent="submit"
        >

          <offer-post-actions
            :data="pointData"
            :submitting="submitting"
          />

          <b-row>
            <b-col
              cols="12"
              xl="8"
              class="order-sm-1"
            >
              <offer-overview
                ref="overview"
                :data="pointData"
              />

            </b-col>
            <!--            <b-col-->
            <!--              lg="6"-->
            <!--              md="6"-->
            <!--            >-->
            <!--              <offer-time-gaps-->
            <!--                :data="pointData"-->
            <!--              />-->
            <!--            </b-col>-->

            <b-col
              cols="12"
              xl="8"
              class="order-sm-2 order-md-3"
            >
              <!--              <offer-open-map-->
              <!--                ref="map"-->
              <!--                :data="pointData"-->
              <!--                @updateLocation="val=>updateLocation(val)"-->
              <!--              />-->
              <location-edit-details
                ref="map"
                :data="pointData"
                :editable="!!($store.state.location.location)"
                :radius="true"
                @update-location="val=>updateLocationEvent(val)"
              />
            </b-col>
            <b-col
              cols="12"
              xl="4"
              class="order-sm-3 order-md-2"
            >
              <offer-media-dropzone
                ref="offerMediaDropzone"
                :data="pointData"
              />
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </div>

  </b-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BForm, BOverlay,
} from 'bootstrap-vue'

import store from '@/store'
import router from '@/router'
import { showToast } from '@/mixins/notification/toasts'

import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import i18n from '@/libs/i18n'
import LocationEditDetails from '@/views/apps/location/edit/LocationEditDetails.vue'
import OfferTimeGaps from './OfferTimeGaps.vue'
import OfferOpenMap from './OfferOpenMap.vue'
import OfferOverview from './OfferOverview.vue'
// import OfferMedia from './OfferMedia.vue'
import OfferPostActions from './OfferPostActions.vue'

import OfferMediaDropzone from './OfferMediaDropzone.vue'

export default {
  components: {
    OfferOverview,
    ValidationObserver,

    BRow,
    BCol,
    OfferTimeGaps,
    OfferMediaDropzone,
    OfferOpenMap,
    // OfferMedia,
    OfferPostActions,
    LocationEditDetails,
    BForm,
    BOverlay,
  },
  mixins: [showToast],

  data() {
    return {
      savedData: {},
      elementKey: 0,
      pointData: {},
      location: {},
      savedPointData: {},
      error404: false,
      restricted: false,
      loading: false,
      submitting: false,
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'apps-service-offer-edit'
              && to.params.id
              && Number.isInteger(parseInt(to.params.id, 10))) {
        this.updatePointData(to.params.id)
      }
      if (to.name === 'apps-service-offer-add-wizard') {
        this.initPoint()
      }
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.pointData = JSON.parse(this.initPointData)
  },
  mounted() {
    if (router.currentRoute.name === 'apps-service-offer-edit'
            && router.currentRoute.params.id
            && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
      this.updatePointData(router.currentRoute.params.id)
    }
  },
  methods: {
    updateLocationEvent(location) {
      this.pointData.address_id = location.id
      this.pointData.lat = location.lat
      this.pointData.lng = location.lng
      this.location = location
      store.commit('location/setLocation', location)
    },

    updatePointData(pointId) {
      this.loading = true
      store.dispatch('point/fetchServiceOffer', { id: pointId })
        .then(response => {
          if (response.status === 404) {
            this.error404 = true
            this.showAlert404()
          } else {
            const ability = defineAbilityForCurrentUser()
            if (ability.can('update', store.getters['point/Point'])) {
              this.pointData = store.getters['point/point']
              const location = {
                address: this.pointData.address,
                lat:this.pointData.lat,
                lng: this.pointData.lng,
                radius: this.pointData.location_radius
              }
              store.commit('location/setLocation', location)
              // this.$refs.requestMediaDropzone.updateUploadedMainImage(this.pointData)
              this.$refs.offerMediaDropzone.updateUploadedFiles(this.pointData)
              this.savedPointData = JSON.stringify(this.pointData)
              this.restricted = false
              this.loading = false
            } else {
              this.restricted = true
              router.push({ name: 'misc-not-authorized' })
            }
          }
        })
    },
    showAlert404() {
      this.$swal({
        title: this.$t('Offer Not Found'),
        text: this.$t('Please chose next action:'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Back to offers list'),
        cancelButtonText: this.$t('Add Offer'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          router.push({ name: 'apps-service-my-offers' })
        } else {
          router.push({ name: 'apps-service-offer-add-wizard' })
        }
      })
    },
    showAlert(next) {
      this.$swal({
        title: this.$t('Are you sure?'),
        text: this.$t("You didn't save the offer"),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          next()
        }
      })
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.save()
        } else {
          const el = document.querySelector('.text-danger')
          if (el) {
            el.scrollIntoView({ block: 'end' })
          }
        }
      })
    },
    initPoint() {
      const initPointData = JSON.parse(this.initPointData)
      store.commit('point/setPoint', initPointData)
      this.pointData = initPointData
      this.savedPointData = JSON.stringify(this.pointData)
      this.elementKey += 1
    },
    requestData() {
      const { pointData } = this

      pointData.location_radius = store.state.location.location.radius / 1000

      pointData.lat = store.state.location.location.lat
      pointData.lng = store.state.location.location.lon || store.state.location.location.lng

      delete pointData.id
      delete pointData.address
      delete pointData.user
      delete pointData.user_id
      delete pointData.point_type
      delete pointData.point_purpose_ids

      // delete pointData.point_purposes
      return pointData
    },
    requestAddress() {

      return {
        country: store.state.location.location.address.country,
        country_code: store.state.location.location.address.country_code,
        postcode: store.state.location.location.address.postcode,
        state: store.state.location.location.address.state,
        locality: store.state.location.location.address.locality,
        road: store.state.location.location.address.road,
        house_number: store.state.location.location.address.house_number,
        apartment_number: store.state.location.location.address.apartment_number,
        address_confirmed: store.state.location.location.address_confirmed,
        viewport: store.state.location.location.viewport,
      }
    },
    save() {
      this.submitting = true
      if (router.currentRoute.name === 'apps-service-offer-add') {
        const payload = { data: this.requestData(), address: this.requestAddress() }
        store.dispatch('point/addServiceOffer', payload).then(response => {
          if ([200, 201, 'success'].includes(response.status)) {
            // if ('OfferMedia' in this.$options.components) {
            // this.$refs.offerMedia.uploadFiles(response.data.data.id)
            // }
            // this.initPoint()
            this.savedPointData = JSON.stringify(this.pointData)
            router.push({ name: 'pages-account-setting', query: { tab: 'services' } })
          }
          this.showToast(response, 'Offer')
        }).finally(() => { this.submitting = false })
      }

      if (router.currentRoute.name === 'apps-service-offer-edit'
              && router.currentRoute.params.id
              && Number.isInteger(parseInt(router.currentRoute.params.id, 10))) {
            const payload = { data: this.requestData(), address: this.requestAddress(), id: router.currentRoute.params.id }
            store.dispatch('point/updateServiceOffer', payload)
            .then(response => {
              // if ('OfferMedia' in this.$options.components) {
              //   this.$refs.offerMedia.uploadFiles(payload.id)
              // }
              store.dispatch('account/fetchUserCurrent')
              this.savedPointData = JSON.stringify(this.pointData)
              router.push({ path: `/service/offer/${response.data.data.id}` })
              this.showToast(response, 'Offer')
            }).catch(error => {
              const { data } = error.response
              let errorMessage = 'Something went wrong'
              if(data.message){
                errorMessage = 'errors.data.'.concat(data.message)
              }
              this.showToast({ status: 'error', statusText: errorMessage }, 'Offer')
            }).finally(() => { this.submitting = false })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const currentData = JSON.stringify(this.pointData, (key, val) => {
      if (typeof val === 'function') {
        if (key === 'name') {
          return val() // make it a string, surround it by parenthesis to ensure we can revive it as an anonymous function
        }
      }
      return val
    })
    if (this.savedPointData !== currentData && !this.error404 && !this.restricted) {
      this.showAlert(next)
    } else {
      next()
    }
  },
  setup() {
    // const POINT_APP_STORE_MODULE_NAME = 'point'
    //
    // // Register module
    // if (!store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.registerModule(POINT_APP_STORE_MODULE_NAME, pointStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_APP_STORE_MODULE_NAME)) store.unregisterModule(POINT_APP_STORE_MODULE_NAME)
    // })

    const pointData = {
      point_type_id: null,
      point_purpose_ids: [],
      name: '',
      description: '',
      lng: 0,
      lat: 0,
      post_status: 'published',
      lang: i18n.locale,
      tags: [],
    }
    const initPointData = JSON.stringify(pointData)
    return {
      pointData,
      initPointData,
    }
  },
}
</script>
